<!--[充值/回收]用户余额-->
<template>
    <el-dialog
        v-model="dialogVisible"
        :title="'User Id: ' + this.postData.userId"
        width="30%"
        draggable
        :close-on-click-modal="false"
    >
        <el-form v-loading="isLoading">
            <el-form-item :label="lang['html.form.username']">
                <el-tag>{{this.postData.username}}</el-tag>
            </el-form-item>

            <el-form-item :label="lang['html.form.money']">
                <el-input-number v-model="postData.money" :min="1" :max="5000" />
            </el-form-item>

            <el-form-item :label="lang['html.form.type']">
                <el-radio-group v-model="postData.type">
<!--                    操作类型,1 = 充值 2 = 回收-->
                    <el-radio :label="1" v-show="postData.type == 1">{{lang['html.form.type.add']}}</el-radio>
                    <el-radio :label="2" v-show="postData.type != 1">{{lang['html.form.type.reduce']}}</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item :label="lang['html.form.selfMoney']">
                <el-tag>{{userMoney}}</el-tag>
            </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeView" :disabled="isDisabled">{{lang['html.button.return']}}</el-button>
        <el-button type="primary" @click="submit">
            {{lang['html.button.submit']}}
        </el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "userList-chengeMoney",
    emits:['updateTable'],
    data(){
        return {
            dialogVisible:false,
            postData:{
                userId:0,//用户id
                type:1,//操作类型,1 = 充值 2 = 回收
                money:1,//[增加/减少]的额度
                username:'',//用户账号
            },
            userMoney:'...',//当前用户剩余余额
            version:this.$cookie.getCookie('version'),
            isLoading:false,
            isDisabled:false,
            lang:this.$lang.getLang('user','userList-changeMoney')
        }
    },
    methods:{
        /**
         * 打开组件
         * @param userId 用户id
         * @param type 操作类型,1 = 充值 2 = 回收
         * @param username 用户账号
         */
        openView(userId,type,username){
            this.closeView();
            this.postData.userId = userId;
            this.postData.type = type;
            this.postData.username = username;
            this.userMoney = '...';
            this.dialogVisible = true;
            this.getUserInfo();
        },
        //关闭组件
        closeView(){
            this.postData = {userId:0, type:1, money:0,};
            this.dialogVisible = false;
        },
        //提交请求
        submit(){
            this.$notify(this.lang['submit.wait']);
            this.isDisabled = this.isLoading = true;
            this.$api.post('User.Money/changeMoney',this.postData).then(res=>{
                this.isDisabled = this.isLoading = false;
                let data = this.$helper.checkRes(res);
                if (!data){
                    //充值失败
                    let code = this.$helper.getResErrorCode(res);
                    this.$helper.alertErrMsg(this.lang,'submit.res.code.',code);
                    return;
                }
                this.$alert(this.lang['submit.res.code.100']);
                // this.getUserInfo();//重新获取用户余额
                //执行成功,通知父组件强制更新页面
                this.$emit('updateTable',{});
            }).catch(err=>{
                this.isDisabled = this.isLoading = false;
                this.$helper.axiosCatch(err);
            })
        },
        //获取用户余额
        getUserInfo(){
            if (this.userId <=0){
                return;
            }
            this.loading = true;
            this.$api.post('User.UserData/getUserData',{
                userId:this.postData.userId
            }).then((res)=>{
                this.loading = false;
                let data = this.$helper.checkRes(res);
                if (!data){
                    this.$alert(this.lang['getUserInfo.error']);
                    return;
                }
                let field = this.version == 1 ? 'money_int' : 'money_time';
                let money = data.data[field] ?? -2;
                this.userMoney = money;
            })
                .catch(err=>this.$helper.axiosCatch(err));
        },
    }
}
</script>

<style scoped>

</style>