<template>
    <div class="view">
        <div class="tools">
            <userMenu
                :selectArr = this.selectTableData
                :tableData = this.tableDataAll
                @filter=watchFilterUser
                style="display: inline;margin-right: 10px"
            />
            <el-button @click="this.$router.go(0);">{{lang['html.el-button.refresh']}}</el-button>
            <el-button @click='viewTableData(1)' :disabled="loading">{{lang['html.el-button.view.all']}}</el-button>
            <el-button @click='viewTableData(2)' :disabled="loading">{{lang['html.el-button.view.filter']}}</el-button>
            <filterMoneyTime
                style="display: inline;margin-left: 10px"
                :userListArr="this.tableDataAll"
                @recvFilterUserMoneyTime="recvFilterUserMoneyTime"
                v-if="version == 2"
            />
            <el-button @click='outputData' :disabled="loading">{{lang['html.el-button.outputData']}}</el-button>
            <el-checkbox
                style="margin-left: 10px"
                v-model="userDataType"
                :label="lang['html.el-checkbox.userDataType']"
                size="small"
                @change="changeUserDataType"
                v-show="userDataTypeVisible"
                :disabled="loading"
            />
        </div>
        <el-table :data="tableData"
                  style="width: 300%"
                  v-loading=loading
                  element-loading-text="Loading..."
                  stripe
                  border
                  @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="50" />

            <el-table-column label="id" width="90" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.agent']" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.agent }}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.username']" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.username }}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.name']" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.nickname']"  highlight-current-row>
                <template #default="scope">
                    {{ scope.row.nickname == '无备注' ? 'Null' :  scope.row.nickname}}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.reg_time']" width="160" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.reg_time }}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.login_time']" width="160" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.login_time }}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.money']"  highlight-current-row>
                <template #default="scope">
                   {{getUserMoneyTime(scope.row.money_time)}} {{ version == 1 ?  scope.row.money_int :  scope.row.money_time}}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.contact']" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.contact}}
                </template>
            </el-table-column>

            <el-table-column :label="lang['html.el-table-column.label.status']" width="60" highlight-current-row>
                <template #default="scope">
                    {{ scope.row.status === 1 ? lang['html.el-table-column.label.status.1'] : lang['html.el-table-column.label.status.default'] }}
                </template>
            </el-table-column>

            <el-table-column label="Tools">
                <template #default="scope">
                    <el-dropdown>
                    <span class="el-dropdown-link">
                         {{lang['html.el-table-column.label.tools']}}
                            <el-icon class="el-icon--right">
                                <arrow-down/>
                            </el-icon>
                    </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="tableMenu(scope.row,1)">{{lang['html.el-dropdown-item.tableMenu.1']}}</el-dropdown-item>
                                <el-dropdown-item @click="tableMenu(scope.row,2)">{{lang['html.el-dropdown-item.tableMenu.2']}}</el-dropdown-item>
                                <el-dropdown-item @click="tableMenu(scope.row,3)">{{lang['html.el-dropdown-item.tableMenu.3']}}</el-dropdown-item>
                                <el-dropdown-item @click="tableMenu(scope.row,4)">{{lang['html.el-dropdown-item.tableMenu.4']}}</el-dropdown-item>
                                <el-dropdown-item @click="tableMenu(scope.row,5)">{{lang['html.el-dropdown-item.tableMenu.5']}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </el-table-column>

        </el-table>

        <div style="text-align: left;display: inline">
            <el-pagination
                style="margin-top: 10px"
                background

                v-show="pageShow"

                layout="total,prev, pager, next,sizes"
                :total=total

                v-model:currentPage="nowPage"
                @current-change="changePage"

                v-model:page-size="pageSize"
                :page-sizes="[20,50,100,500,1000]"
                @size-change="handleSizeChange"

                :disabled="loading"

            />
        </div>
    </div>
<!--    用户信息组件-->
    <userlistInfo
        ref=userListInfoRef
        @updateTable="updateTable"
    />
<!--    [充值/回收]用户余额-->
    <userListChengeMoney
        ref="userListChangeMoney"
        @updateTable="updateTable"
    />
</template>

<script>
import {ElNotification} from 'element-plus'
import paging from "../../utils/paging";
import userMenu from './userList/userList-menu';
import userlistInfo from './userList/userList-info';
import userListChengeMoney from "./userList/userList-chengeMoney";
import filterMoneyTime from './userList/userList-filterMoneyTime';
import User from "../../utils/User";

export default {
    name: "UserList",
    components:{
        userMenu,//菜单
        userlistInfo,//编辑用户信息
        userListChengeMoney,//[充值/回收]用户余额
        filterMoneyTime
    },
    data(){
        return {
            loading:true,//正在加载数据遮蔽窗
            tableData:[],//当前表格显示数据
            version:this.$cookie.getCookie('version'),
            total:0,//表格总数
            nowPage:1,//当前页数
            pageSize:20,//每页显示数量
            selectTableData:[],//当前被多选的用户
            tableDataAll:[],//表格全部数据(未分页)
            pageShow:false,//分页组件是否显示
            filterData:[],//筛选后的数据
            lang:this.$lang.getLang('user','userList'),
            time:new Date(),//当前时间
            userDataType:false,//[用户数据类型]false=只显示自己旗下的用户 true=显示包含下级代理商的用户
            userDataTypeVisible:false,//[用户数据类型]是否显示功能
            agentUserName:User.getUserName(),//当前代理商账号
            nowEditUserId:0,//当前正在编辑用户数据的用户id
        }
    },
    methods:{
        //加载用户列表数据
        getUserList(){
            this.loading = true;
            ElNotification({message: this.lang['getUserList.wait'], type: 'info',});
            this.$api.post('User.UserData/getUserList',{
                userDataType : this.userDataType ? 1 : 2,
            }).then((res)=>{
                //解析数据
                let data = res.data ?? null;
                if(!data){
                    ElNotification({message: this.lang['getUserList.error.userList'], type: 'error',});
                    return;
                }
                if(data.code != 100){
                    ElNotification({message: this.lang['getUserList.error.data'], type: 'error',title:'Error.code:' + data.code ?? '-1'});
                    return;
                }
                paging.init(data.data,20);
                this.tableDataAll = paging.getAllData();
                this.getTableData();
                this.loading = false;
                ElNotification({message: this.lang['getUserList.tips.userNum'] + this.total , type: 'success',});
            })
        },
        //更新表格数据
        getTableData(){
            this.tableData = paging.getPageData();
            this.total = paging.getLength();
            this.pageShow = true;
        },
        //点击了[查看/编辑]用户
        editUser(data){
            let userId = data.id;//用户id
            if (userId == null){
                this.$alert(this.lang['editUser.error.userid']);
                return;
            }
            this.$refs.userListInfoRef.setShow(true,userId);
            // console.log('editUser',index,data,userId)
        },
        //本函数被调用则页数被改动,并实时刷新表格数据
        changePage(newPage) {
            this.nowPage = newPage;
            paging.setPage(newPage);
            this.getTableData();
        },
        /**
         * 表格被多选
         * @param selectArr 被多选的用户[数组]
         */
        handleSelectionChange(selectArr){
            this.selectTableData = selectArr;
        },
        //收到子组件传来筛选后的用户数据
        watchFilterUser(userData){
            // console.log('watchFilterUser',userData);
            this.filterData = userData;
            this.viewTableData(2);
        },
        /**
         * 切换表格显示的数据[全部数据/筛选数据]
         * @param mode 1=全部数据 2=筛选数据
         */
        viewTableData(mode){
            this.$notify({message:this.lang['viewTableData.wait'],type: 'info',});
            if (mode === 1){
                paging.init(this.tableDataAll,20);
                this.changePage(1)
            }else {
                // this.tableData = this.filterData;
                // this.pageShow = false;
                paging.init(this.filterData,20);
                this.changePage(1)
                this.$message({
                    type:'success',
                    message:this.lang['viewTableData.tips.filterNum'] + this.filterData.length,
                });
            }
        },
        //表格内菜单回调函数
        tableMenu(data,type){
            this.nowEditUserId = data.id;
            let routeUrl;
            switch (type){
                case 1:
                    //编辑信息
                    this.editUser(data);
                    break;
                case 2:
                    //充值余额
                    this.$refs.userListChangeMoney.openView(data.id,1,data.username);
                    break;
                case 3:
                    this.$refs.userListChangeMoney.openView(data.id,2,data.username);
                    //回收余额
                    break;
                case 4:
                    //查询下载
                    // this.$router.push({name:'user.Download',params:{username:data.username}});
                    routeUrl = this.$router.resolve({name:'user.Download',params:{username:data.username}});
                    window.open(routeUrl.href, "_blank");
                    break;
                case 5:
                    //查询用户使用的充值码记录
                    // this.$router.push({name:'user.userUseRecKey',params:{username:data.username}});
                    routeUrl = this.$router.resolve({name:'user.userUseRecKey',params:{username:data.username}});
                    window.open(routeUrl.href, "_blank");
                    break;

            }
        },
        //每页显示数量变动
        handleSizeChange(newSize){
            this.$notify({message:this.lang['viewTableData.wait'],type: 'info',});
            this.loading = true;
            this.pageSize = newSize;
            // this.tableData = [];
            paging.setSize(this.pageSize);
            setTimeout(()=>{
                this.changePage(1);
                this.loading = false;
            },800);
        },
        //[月费版]计算剩余时间
        getUserMoneyTime(time){
            if (this.version == 1){
                return '';
            }
            let obj = new Date(time);
            let int = obj.getTime() - this.time.getTime();
            let res;
            if (int <= 0){
                res = this.lang['getUserMoneyTime.timeout'];
            }else{
                res = parseInt((int/(24*1000*3600))) + this.lang['getUserMoneyTime.day'];
            }
            return '[' + res + ']';
        },
        // [月费版]接收筛选余额后的用户
        recvFilterUserMoneyTime(data){
            paging.init(data,20)
            this.getTableData()
        },
        //触发切换用户数据类型
        changeUserDataType(){
            this.$message({message:this.lang['changeUserDataType.wait'],type:'info'});
            this.getUserList();
        },
        //[初始化]检查当前代理商是否有越级管理功能
        initUserDataType(){
            //定义允许使用该功能的白名单
            let arr = ['yue','xiao'];
            //检查当前代理商是否有权限使用这功能
            this.userDataTypeVisible = arr.includes(this.agentUserName);
        },
        //导出用户数据
        outputData(){
            this.$confirm(this.lang['outputData.message'],'Warning',{
                confirmButtonText:this.lang['outputData.confirmButtonText'],
                cancelButtonText:this.lang['outputData.cancelButtonText'],
            }).then(()=>this.makeOutPutUserData(paging.getAllData()))
            .catch(()=>this.makeOutPutUserData(this.selectTableData));
        },
        //导出用户数据 功能函数
        makeOutPutUserData(arr){
            if (arr.length <= 0){
                this.$alert(this.lang['makeOutPutUserData.error.length']);
                return;
            }
            this.$message({message:this.lang['makeOutPutUserData.tips.length'] + arr.length,type:'info'});
            let line = '\r\n';
            let str = '';
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                let res = [
                    temp['agent'],
                    temp['username'],
                    temp['name'],
                    temp['nickname'],
                    temp['reg_time'],
                    temp['login_time'],
                    this.version == 1 ? temp['money_int'] : temp['money_time'],
                    temp['contact'],
                ];
                str += res.toString() + line;
            }
            let filed = ['AGENT','USERNAME','NAME','NICKNAME','REGISTER','LOGIN','MONEY','CONTACT'];
            let data = filed.toString() + line + str;
            this.$helper.downLoad(data,'UserData.csv');
        },
        /**
         * [子组件]收到通知更新表格数据
         * 无缝更新表格的某个用户数据,并显示出来
         * 代理商感知不到数据已更新
         */
        updateTable(){
            this.isLoading = true;
            this.$api.post('User.UserData/getUserData',{userId:this.nowEditUserId})
                .then(res=>{
                    if (!this.$helper.checkRes(res)){
                        this.confirmRefreshPage();
                    }
                    this.updateUserListData(res.data.data);
                })
                .finally(()=>this.loading = false);
        },
        /**
         * [updateTable]更新[this]与[分页类]的数据,并更新当前表格显示
         * @param userInfo
         */
        updateUserListData(userInfo){
           this.tableDataAll = this.updateArr(this.tableDataAll,this.nowEditUserId,userInfo);
           this.filterData = this.updateArr(this.filterData,this.nowEditUserId,userInfo);
           let paginData = this.updateArr(paging.getAllData(),this.nowEditUserId,userInfo);
           let pageConfig = {
               size:paging.pageSzie,
               page:paging.pageNum,
           };
           paging.init(paginData,pageConfig.size);
           paging.setPage(pageConfig.page);
           this.tableData = paging.getPageData();
        },
        /**
         * 基于指定用户数据,更新用户数据数组的成员
         * @param arr 用户数据数组
         * @param id 用户id
         * @param info 用户数据信息
         * @return {*}
         */
        updateArr(arr,id,info){
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                if (temp.id === id){
                    setTimeout(
                        ()=>this.$message({message:this.lang['updateArr.tips'],type:'success'}),
                        10
                    );
                    arr[arrKey] = info;
                    break;
                }
            }
            return arr;
        },
        /**
         * [updateTable]无缝更新失败,询问用户是否要刷新页面以更新数据
         */
        confirmRefreshPage(){
            this.$confirm(
                this.lang['updateTable.message'],
                this.lang['updateTable.title'],
                {
                    cancelButtonText:'×',
                    confirmButtonText: '√',
                },
            ).then(()=>{
                //延迟x毫秒后刷新页面
                setTimeout(()=>{
                    this.$router.go(0);
                },10);
            }).catch(()=>this.$notify({message:this.lang['updateTable.catch'],type:'warning',duration:8000}));
        },

    },
    mounted() {
        //初始化用户列表
        this.getUserList();
        //检查当前代理商是否有越级管理功能
        this.initUserDataType();
    },
}
</script>

<style scoped>
.view{
    height: 500px;
}
.tools{
    /*display: inline;*/
    margin-bottom: 5px;
}
</style>